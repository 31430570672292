import React from 'react'




class ChatBot extends React.Component {


  componentDidUpdate(){
    this.textInput.focus();
  }


  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }



  handleSubmit(event) {
    event.preventDefault();
    var input = this.state.value;
    var answer = document.getElementById("answer");
    var answer_link = document.getElementById("answer_link");

    this.setState({value: ""});

    if (input === 'immersion') {
    answer.innerHTML = "Some people want their communications to sound and look like ads, because then it feels to them like they are advertising. But they are not. Proper communication is immersive, meaning it creates a world for your readers to dive into, tells a →story and conveys a compelling argument.";
    }

    else if (input === 'story') {
    answer.innerHTML = "In corporate communication, brands or products must be characterized like heroes in a novel, with a idiosynchratic way of talking, a unique identity and an inner conflict. This creates →immersion, with the only alternative being →corporate dadaism.";
    }

    else if (input === 'corporate dadaism') {
    answer.innerHTML = "Corporate dadaism is usually created unintentionally by professionals so in love with their own →buzzwords and slogans that they do not notice that they are creating nonsense. A good test for corporate dadaism is usually to check if the opposite would make sense, e.g. 'We make your strategy work' - what else, make it fail? But sometimes you want corporate dadaism to achieve →strategic ambiguity.";
    }

    else if (input === 'strategic ambiguity') {
    answer.innerHTML = "Before the internet, it was actually better to say almost nothing with as many words as possible, or be ambiguous. For example if you have something to hide, if you adress stakeholders with opposing interests or if you sell weapons, pharmaceuticals or raw resources. Strategic ambiguity was used extensively by companies in the 80s and 90s but became obsolete with the internet, because people can now just google your shit and you look ridiculous if you try to hide it. The modern way is transparency.";
    }


    else if (input === 'buzzwords') {
    answer.innerHTML = "If you use these, you sound like a parrot from 2017 or older: storytelling, design thinking, influencers, thought leaders, agile, ai-powered, gamechanger, disruptive, agents of change, the cloud, client-centric.";
    }


    else if (input === 'style') {
    answer.innerHTML = "Copywriters who do not read books get some sort brain damage and start thinking and writing in headlines. Only very short sentences. Mere words. It gets boring fast. Kills →immersion. True, a headline cannot be short enough. But beyond that, language is a musical instrument, with the vocals forming a melody and the sentence length creating a rhythm.";
    }

    else if (input === 'contact') {
    answer.innerHTML = "hello@kevinbloch.ch";
    }

    else if (input === 'work') {
      var url = "../work"
    var win = window.open(url, '_blank');
    win.focus();
    }

    else if (input === 'home') {
      var url = "../"
    var win = window.open(url, '_blank');
    win.focus();
    }

    else if (input === 'guestbook') {
      var url = "../guestbook"
    var win = window.open(url, '_blank');
    win.focus();
    }

    else if (input === 'about') {
      var url = "../about"
    var win = window.open(url, '_blank');
    win.focus();
    }

    else if (input === 'old website') {
      answer_link.href = "https://alpin111.github.io/blo.ch/"
      answer_link.innerHTML = "For historic purposes and its outstanding →brutalism.";
      answer_link.target = "_blank"

    }

    else if (input === 'brutalism') {
      answer_link.href = "http://brutalistwebsites.com/kevinbloch.ch/"
      answer_link.innerHTML = "The →old website got featured on brustalistwebsites.com.";
      answer_link.target = "_blank"

    }



    else {answer.innerHTML = "Unknown word. Try one of these: immersion, story, style, contact, ↗home, ↗about, old website, brutalism, corporate dadaism, strategic ambiguity, ↗guestbook or ↗work.";}
  }






  focus () {
    document.getElementById('text_field').focus();
  }





  render() {
    return (
      <form onSubmit={this.handleSubmit}>

          {this.props.string}

          <span id="black">
          <input type="text" value={this.state.value} size={this.state.value.length+1} maxLength="40" onChange={this.handleChange} ref={(input) => { this.textInput = input; }} id="text_field" onBlur={this.focus}  autoFocus  />
          <span id="Cursor">|</span>
          </span>

        <input type="submit" value=" ENTER ↵" />
        <p id='answer'></p>
        <a id='answer_link' href=""></a>

      </form>
    );
  }
}

export default ChatBot;

// <Typist avgTypingDelay={0} stdTypingDelay={0} cursor={{show:false}}> </Typist>
// <English Language={location.search}>
//       <Type>
//       Some people want their communications to sound and look like ads, because then it feels to them like they are advertising.
//       <p/>But they are not. Proper communication is immersive, meaning it creates a world, tells a story and conveys a compelling argument.
//
//       <Typist.Delay ms={2000} />
//       <p/>This is why I believe that in corporate communication, brands or products must be characterized like heroes in a novel, with a way of talking, an identity and an inner conflict.
//
//       <Typist.Delay ms={2000} />
//       <p/>This is not optional. Any communication today competes with a myriad of others, like netflix series and video games.
//
//
//
//       </Type>
// </English>
//
//
//
// <Deutsch Language={location.search}>
//       <Type>
//       Das ist die Webseite von <LinkABOUT location={location}>Kevin Bloch.</LinkABOUT> Hier ist seine <Link to="/work/">Arbeit.</Link> <Link to="/page-2/">schreib@kevinbloch.ch</Link> / 079 716 73 61. Read it in <LinkEN location={location}>English.</LinkEN>
//       </Type>
// </Deutsch>
