
import React from 'react'
import {SiteTitle} from '../components/linksetc.js'
import ChatBot from '../components/chatbot'

import blueStyles from '../components/blue.module.css'
import '../components/basic.css'





const BluePage = ( {location} ) => (

  <>
  <SiteTitle string="Navigation" />

  <div className={blueStyles.blue}>


    <ChatBot string="Enter a word to navigate"/>


</div></>
)

export default BluePage
